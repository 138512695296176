<template>
  <v-container class="site_container">
    <v-row>
        <v-row
          v-for="(profile, i) in profiles"
          :key="profile.id"
        >
          <v-col
            cols="12"
            md="6"
            sm="12"
            class="padding_heigh_30"
          >
            <v-img
              :alt="profile.title"
              contain
              :src="url_api + '/saved/image/profile/' + profile.image"
              transition="scale-transition"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
            class="padding_heigh_30"
          >
            <p>{{profile.profile}}</p>
          </v-col>

          <v-col
            cols="12"
            md="12"
            sm="12"
            class="padding_heigh_30"
          >
            <div class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectEditing (i)"
                  >
                    <v-icon>mdi-file-upload</v-icon>
                  </v-btn>
                </template>
                <span>Upload</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-row>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Edit profile</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-file-input
                        accept="image/*"
                        label="Select your image"
                        v-model="new_image"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-textarea
                        name="input-7-1"
                        label="Default style"
                        v-model="new_profile"
                        hint="Hint text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveData()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
      <v-row justify="center">

        <v-dialog
          v-model="message_alert"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{message.title}}
            </v-card-title>

            <v-card-text>
              {{message.description}}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="message_alert = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { URL_API, headersToken, headersTokenMultipart } from '../../control'
import axios from 'axios'
export default {
  created () {
    this.getProfiles()
  },
  data: () => ({
    url_api: URL_API,
    profiles: [],
    new_image: null,
    new_profile: null,
    dialog: false,
    id: null,
    key_editing: null,
    message: {},
    message_alert: false
  }),
  methods: {
    async getProfiles () {
      axios.get('/api/admin/profile', headersToken(this.$store.state.auth.token)).then((response) => {
        this.profiles = response.data
      })
    },
    selectEditing (key) {
      this.key_editing = key
      this.dialog = true
      this.new_image = null
      this.new_profile = this.profiles[this.key_editing].profile
    },
    saveData () {
      let id = null
      if (this.key_editing !== null) {
        id = this.profiles[this.key_editing].id
      }
      const formData = new FormData()
      formData.append('document_file_0', this.new_image)
      formData.append('profile', this.new_profile)
      formData.append('id', id)
      axios.post('api/admin/profile',
        formData,
        headersTokenMultipart(this.$store.state.auth.token)
      ).then((response) => {
        this.getProfiles()
        this.dialog = false
        this.message.title = 'Record saved successfully'
        this.message.description = 'click close to continue'
        this.message_alert = true
      })
    }
  }
}
</script>
